.nav-tabs{
    margin-top: -91px;
    z-index: 1000;
    background: rgba(255,255,255, 0.7);
    padding: 0 !important;
    position: relative;
}

.nav-item{
    height: 31px !important;
    width: 20%;
}

li.nav-item{
    width: 120px;
    font-size: 12pt;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 5px;
}

li.nav-item a{
    color: #000;
    font-style: bold;
    text-align: center;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
}

li.nav-item a>p{
    font-size: 9pt;
    margin: 0;
}

.nav-tabs .nav-link{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14pt;
    color: rgba(0,0,0,0.5);
    padding: 0 !important;
}

.nav-tabs .nav-link.active{
    background-color: #f5DF4d !important;
    color: rgba(0,0,0,1);
}

.nav-link.active{
    background-color: #f5DF4d !important;
    color: rgba(0,0,0,1);
}

.leaflet-container{
    height: 720px !important;
}

.nav-link{
    padding: 0 !important;
}

.nav-link img{
    max-height: 25px;
    opacity: 0.65;
}

.leaflet-marker-icon{
    width: 30px;
}

.leaflet-shadow-pane{
    display: none !important;
}

.navbar-toggleable-md{
    position: relative;
    z-index: 1000;
    background: rgba(255,255,255, 0.7);
}

.defaultshow{
    height: 80px;
}

.row .nav-item{
    height: 60px !important;
}

#message{
    display: none;
}

@media only screen and (max-width: 600px) and (orientation:portrait){
    #mapcontainer {
        display: none;
    }

    #message{
        display: flex;
        align-content: center;
        justify-content: center;
        height: 100vh;
    }
}


@media only screen and (min-width: 625px) and (max-width: 1024px) and (orientation: landscape)  {

    #mapcontainer {
        width: 100vw;
        overflow-x: hidden;
        position: absolute;
    }

    .leaflet-container{
        max-height: 100vh !important;
        height: 100vh !important;
    }

    .nav-link img{
        display: none;
    }

    li.nav-item{
        width: 60px !important;
    }
}


@media only screen and (orientation:portrait){

#mapcontainer {
  
     height: 100vw;
  
     -webkit-transform: rotate(90deg);
  
     -moz-transform: rotate(90deg);
  
     -o-transform: rotate(90deg);
  
     -ms-transform: rotate(90deg);
  
     transform: rotate(90deg);
  
    }
  
  }
  
  @media only screen and (orientation:landscape){
  
    #mapcontainer {
  
    -webkit-transform: rotate(0deg);
  
    -moz-transform: rotate(0deg);
  
    -o-transform: rotate(0deg);
  
    -ms-transform: rotate(0deg);
  
    transform: rotate(0deg);
  
     }

    }